const content = [
  {
    id: "kundtjanst",
    title: "Kundtjänst och reklamation",
    items: [
      {
        id: "kt-halmstad",
        title: "Kundtjänst Halmstad",
        items: [
          {
            id: "kt-halmstad-1",
            content: "035-18 30 50",
            link: "tel:035183050",
            type: "margin-y",
          },
          {
            id: "kt-halmstad-2",
            content: "Mån-Fre: 07:45-16:30",
          },
          {
            id: "kt-halmstad-3",
            content: "Lördagar: 09:00-13:00",
          },
        ],
      },
      {
        id: "kt-norrkoping",
        title: "Kundtjänst Norrköping",
        items: [
          {
            id: "kt-norrkoping-1",
            content: "011-20 29 98",
            link: "tel:011202998",
            type: "margin-y",
          },
          {
            id: "kt-norrkoping-2",
            content: "Mån-Fre: 08:00-17:00",
          },
          {
            id: "kt-norrkoping-3",
            content: "Lördagar: 09:00-13:00",
          },
        ],
      },
      {
        id: "kt-umea",
        title: "Kundtjänst Umeå",
        items: [
          {
            id: "kt-umea-1",
            content: "090-17 16 65",
            link: "tel:090171665",
            type: "margin-y",
          },
          {
            id: "kt-umea-2",
            content: "Mån-Fre: 07:00-16:00",
          },
          {
            id: "kt-umea-3",
            content: "Lördagar: 09:00-12:00",
          },
        ],
      },
    ],
  },{
    id: "lagersaljkontor",
    title: "Lager och säljkontor",
    items: [
      {
        id: "ls-enkoping",
        title: "Enköping, lager och sälj",
        breakOnComma: true,
        items: [
          {
            id: "ls-enkoping-1",
            content: "Växel: 0171-16 63 00",
            link: "tel:0171166300",
            type: "margin-y"
          }
        ],
      },
      {
        id: "ls-halmstad",
        title: "Halmstad, lager och sälj",
        breakOnComma: true,
        items: [
          {
            id: "ls-halmstad-1",
            content: "Växel: 035-18 30 00",
            link: "tel:035183000",
            type: "margin-y"
          }
        ],
      },
      {
        id: "ls-norrkoping",
        title: "Norrköping, lager och sälj",
        breakOnComma: true,
        items: [
          {
            id: "ls-norrkoping-1",
            content: "Växel: 011-20 29 00",
            link: "tel:011202900",
            type: "margin-y"
          }
        ],
      },
      {
        id: "ls-umea",
        title: "Umeå, lager och sälj",
        breakOnComma: true,
        items: [
          {
            id: "ls-umea-1",
            content: "Växel: 090-17 16 00",
            link: "tel:090171600",
            type: "margin-y"
          }
        ],
      }
    ]
  }, {
    id: "saljkontor-1",
    title: "Säljkontor",
    items: [
      {
        id: "saljkontor-gbg",
        title: "Göteborg, Säljkontor",
        breakOnComma: true,
        items: [
          {
            id: "saljkontor-gbg-1",
            content: "Växel: 031-742 14 00",
            link: "tel:0317421400",
            type: "margin-y"
          }
        ],
      },
      {
        id: "saljkontor-jkp",
        title: "Jönköping, Säljkontor",
        breakOnComma: true,
        items: [
          {
            id: "saljkontor-jkp-1",
            content: "Växel: 036-14 96 10",
            link: "036149610",
            type: "margin-y"
          }
        ],
      },
      {
        id: "saljkontor-lulea",
        title: "Luleå, Säljkontor",
        breakOnComma: true,
        items: [
          {
            id: "saljkontor-lulea-1",
            content: "Växel: 0920-23 73 00",
            link: "tel:0920237300",
            type: "margin-y"
          }
        ],
      },
      {
        id: "saljkontor-malmo",
        title: "Malmö, Säljkontor",
        breakOnComma: true,
        items: [
          {
            id: "saljkontor-malmo-1",
            content: "Växel: 090-17 16 00",
            link: "tel:090171600",
            type: "margin-y"
          }
        ],
      }
    ]
  }, {
    id: "saljkontor-2",
    title: "Säljkontor",
    items: [
      {
        id: "saljkontor-sthlm",
        title: "Stockholm, Säljkontor",
        breakOnComma: true,
        items: [
          {
            id: "saljkontor-sthlm-1",
            content: "Växel: 08-772 25 00",
            link: "tel:087722500",
            type: "margin-y"
          }
        ],
      },
      {
        id: "saljkontor-sundsvall",
        title: "Sundsvall, Säljkontor",
        breakOnComma: true,
        items: [
          {
            id: "saljkontor-sundsvall-1",
            content: "Växel: 090-17 16 00",
            link: "tel:090171600",
            type: "margin-y"
          }
        ],
      },
      {
        id: "saljkontor-uddevalla",
        title: "Uddevalla, Säljkontor",
        breakOnComma: true,
        items: [
          {
            id: "saljkontor-uddevalla-1",
            content: "Växel: 0522-65 38 00",
            link: "tel:0522653800",
            type: "margin-y"
          }
        ],
      },
      {
        id: "saljkontor-ostersund",
        title: "Östersund, Säljkontor",
        breakOnComma: true,
        items: [
          {
            id: "saljkontor-ostersund-1",
            content: "Växel: 090-17 16 00",
            link: "tel:090171600",
            type: "margin-y"
          }
        ],
      }
    ]
  }
];

export default content;