import './style.scss';
import Header from "../../components/header";
import Footer from "../../components/footer";
import BrandCardsList from "../../components/brand-cards-list";
import FAQ from "../../components/faq";

function Home() {
  return (
    <>
      <Header channel={'home'} />

      <div className="content--wrapper">
        <div className="content text-center">
            <h1>Vi kan inte hitta sidan du letar efter</h1>
            <div className="homepage-content--wrapper">
            <p>Tyvärr kan vi just nu inte visa sidan du ville nå. Det beror på att vi genomför uppdateringar eller upplever tekniska problem. Så snart det är möjligt kommer allt fungera igen.</p>
            <p>Behöver du komma i kontakt med oss? Gå direkt till kontaktuppgifterna för respektive företag/sajt nedan.</p>
            <p>Läs mer i Frågor och svar längre ned på denna sida.</p>
          </div>
        </div>
      </div>
      
      <div className="brand-card-list--wrapper">
        <BrandCardsList />
      </div>
      
      <FAQ />

      <Footer />
    </>
  );
}

export default Home;
