const CONTENT = [
  {
    id: "q1",
    question: "Varför hamnade jag här?",
    answer: `
        <p>För tillfället så har vi någon form av störning som tex att vi genomför en större uppdatering eller upplever tekniska problem. Då har vi den här sidan där vi kan ge dig svar på vissa frågor eller hjälpa dig komma i kontakt med rätt person.</p>
        <p>Du kan vara lugn, vi jobbar på det och så snart det är möjligt kommer allt att fungera som vanligt igen.</p>`,
  },
  {
    id: "q2",
    question: "Kan jag handla från denna sida?",
    answer: `<p>Nej, du kan inte lägga din order på denna sida.</p>
             <p>Som kund till <a href="/martinservera">Martin & Servera</a> eller <a href="/galatea">Galatea</a> kan du antingen vänta tills allt fungerar igen eller kontakta ditt säljkontor vid brådskande ärende.</p>
             <p>Är du kund till <a href="/gronsakshallen">Grönsakshallen Sorunda</a> eller <a href="/kotthallen">Kötthallen Sorunda</a> når du deras e-handel via kontaktsidorna på denna sajt.</p>`
  },
  {
    id: "q3",
    question: "Hur gör jag om jag måste få hjälp snabbt?",
    answer: `<p>Har du ett brådskande ärende som att du tex behöver lägga din order så hänvisar vi till respektive säljkontors sida för kontaktuppgifter.</p>`,
  },
  {
    id: "q4",
    question: "Hur länge kommer det vara problem?",
    answer: `<p>Vi försöker alltid åtgärda problem och genomföra uppgraderingar så snabbt som möjligt. Ibland kan det dock ta lite längre tid.</p><p>Har du ett brådskande ärende som att du tex behöver lägga din order så hänvisar vi till respektive säljkontors sida för kontaktuppgifter.</p>`,
  },
  {
    id: "q5",
    question: "Hur når jag den information jag egentligen sökte?",
    answer: `<p>Så snart allt fungerar som vanligt igen så hittar du all vår information på våra ordinarie sidor. Vi jobbar så fort vi kan.<br>Har du ett brådskande ärende som att du tex behöver lägga din order så hänvisar vi till respektive säljkontors sida för kontaktuppgifter.</p>`,
  },
];

export default CONTENT;