const content = [
  {
    id: "kontakt",
    title: "Kontaktinformation",
    items: [
      {
        id: "besok",
        title: "Besöksadress",
        items: [
          {
            id: "besok-1",
            content: "Galatea AB",
            type: "margin-y"
          },
          {
            id: "besok-2",
            content: "Gustavslundsvägen 133, 9 tr",
            type: "margin-y"
          },
          {
            id: "besok-3",
            content: "167 51 Bromma",
            type: "margin-y"
          },
          {
            id: "besok-4",
            content: "Visa på karta",
            type: "margin-y",
            link: "https://www.google.com/maps/place/Galatea+Spirits+AB/@59.3294,17.9813205,17z/data=!3m1!4b1!4m5!3m4!1s0x465f78212f446e89:0x37fc94ac5f8211c8!8m2!3d59.3293973!4d17.9835092"
          }
        ],
      },
      {
        id: "kundservice",
        title: "Kundservice",
        items: [
          {
            id: "kundservice-1",
            content: "020-21 24 00",
            link: "tel:020212400",
            type: "margin-y"
          },
          {
            id: "kundservice-2",
            content: "Maila kundservice",
            link: 'mailto:info@galatea.se',
            type: "margin-y"
          }
        ],
      },
      {
        id: "order",
        title: "Ordermottagning",
        items: [
          {
            id: "order-1",
            content: "020-21 24 10",
            link: "tel:020212410",
            type: "margin-y",
          },
          {
            id: "order-2",
            content: "Mån-Fre: 08:00-17:00",
            type: "margin-y"
          },
          {
            id: "order-3",
            content: "Maila order",
            link: 'mailto:order@galatea.se',
            type: "margin-y"
          }
        ],
      }
    ]
  }
];

export default content;