import './style.scss';
import msContent from './content-ms';
import gtContent from './content-gt';
import rmContent from './content-rm';
import ghContent from './content-gh';
import fhContent from './content-fh';
import khContent from './content-kh';

function InfoBlock(props) {

  const channelFragments = (channel) => {
    switch (channel) {
        case 'martinservera':
            return msContent;
        case 'galatea':
            return gtContent;
        case 'raddamat':
            return rmContent;
        case 'gronsakshallen':
            return ghContent;
        case 'fiskhallen':
            return fhContent;
        case 'kotthallen':
            return khContent;
        default:
            return [];
    }
  }

  const breakStringOnComma = (string) => {
    // Breaks string to a new line on comma
    const arr = string.split(',');
    return {__html: arr.length > 0 ? `${arr[0]},<br />${arr[1]}` : arr[0]};
  }

  return (
    <div
      className={
        props.channel ? `info-block info-block-${props.channel}` : "info-block"
      }
    >

      {channelFragments(props.channel).map(fragment => (
          <div className="info-block--box" key={fragment.id}>
              <h2 className="info-block--title">{fragment.title}</h2>

              {fragment.link &&
                <a href={fragment.link.href} target="_blank" rel="noreferrer" className="info-block--link"><span className="info-block--link--text">{fragment.link.title}</span> <i className="fa fa-external-link"></i></a>
              }

              <div className="info-block--content">

                <div className="info-block--fragments">
                    {fragment.items.map(fragmentItem => (
                            <ul className={`info-block--fragment info-block--fragment--${fragment.items.length}-cols`} key={fragmentItem.id}>
                                <li className="info-block--fragment--title">
                                    {fragmentItem.breakOnComma ? 
                                        <span dangerouslySetInnerHTML={breakStringOnComma(fragmentItem.title)}></span> : 
                                        fragmentItem.title
                                    }
                                </li>
                                
                                {fragmentItem.items.map(fragmentContent => (
                                    <li 
                                        className={fragmentContent.type ? 
                                            `info-block--fragment--item info-block--fragment--item--${fragmentContent.type}` : 
                                            'info-block--fragment--item'}
                                        key={fragmentContent.id}
                                    >
                                        {
                                            fragmentContent.link ? 
                                                <a href={fragmentContent.link} target="_blank" rel="noreferrer">{fragmentContent.content}</a> : 
                                                    fragmentContent.isHtml ? <span dangerouslySetInnerHTML={{ __html: fragmentContent.content }}></span> : 
                                                    <>{fragmentContent.content}</>
                                        }
                                    </li>
                                ))}

                            </ul>
                    ))}
                </div>

                {fragment.footer &&
                    <div className="info-block--footer">
                        {fragment.footer}
                    </div>
                }

              </div>

          </div>

      ))}
    </div>
  );
}

export default InfoBlock;
