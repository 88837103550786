import "./style.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {CHANNELS, getChannelName} from '../../App';

function FlyoutMenu(props) {
  const [backdropActive, setBackdropActive] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    
    if(!props.open) {
        // Wait for css transition to finish before removing backdrop from DOM
        setTimeout(() => {
            setBackdropActive(props.open)
        }, 200)
    }else {
        setBackdropActive(props.open)
    }

  }, [props.open])

  const onChannelClick = (channel) => {
    navigate(`/${channel}`);
    props.closeMenu();
  }

  return (
    <>
        <div className={props.open ? 'flyout-menu flyout-menu--open' : 'flyout-menu'}>
            <div className="flyout-menu--content">
                <div className="flyout-menu--close" onClick={() => props.closeMenu()}>
                    <i className="fal fa-times"></i>
                </div>

                <ul className="flyout-menu--menu-items">
                    <li className="flyout-menu--menu-items--item" onClick={() => onChannelClick('')}>
                        Hem
                    </li>
                    {CHANNELS.map(channel => 
                        <li className="flyout-menu--menu-items--item" onClick={() => onChannelClick(channel)} key={channel}>
                            {getChannelName(channel)}
                        </li>
                    )}
                </ul>

                <div className="flyout-menu--footer">
                    © Martin & Servera-gruppen
                </div>

            </div>
        </div>
        
        { backdropActive &&
            <div className={props.open ? 'flyout-menu--backdrop flyout-menu--backdrop--active' : 'flyout-menu--backdrop'} onClick={() => props.closeMenu()}></div>
        }
    </>
  );
}

export default FlyoutMenu;
