import './style.scss';
import { useState } from 'react';
import CONTENT from './content';

function FAQ() {
    const [activeQuestions, setActiveQuestions] = useState([]);
    const [questionHeight, setQuestionHeight] = useState([]);
    
    const questionIsOpen = (id) => activeQuestions.includes(id);
    const getQuestionHeight = (id) => {
        const height = questionHeight.find(q => q.id === id);
        return height.height;
    };
    
    const onQuestionClick = (e, id) => {

        // Prevent question to close if user clicked a link
        if(e.target.nodeName === 'A') {
            return false;
        }
        
        if(!questionHeight.filter(q => q.id === id).length) {
            setQuestionHeight([...questionHeight, {id, height: e.target.scrollHeight}]);
        }

        const newActiveQuestions = questionIsOpen(id) ? activeQuestions.filter(qid => qid !== id) : [...activeQuestions, id];

        setActiveQuestions(newActiveQuestions)
    }

    const stringAsHtml = (string) => {
        return  {__html: string};
    }

    return (
        <div className="faq-section">
            <h2 className="faq-section--header">Vanliga frågor och svar</h2>
            <p className="sub-header">Här hittar du svaren på våra vanligaste frågor.</p>

            <ul className="faq-section--questions">
                {
                    CONTENT.map(question => 
                        <li 
                            key={question.id} 
                            className={questionIsOpen(question.id) ? 'faq-section--questions--item faq-section--questions--item__active' : 'faq-section--questions--item'}
                            onClick={(e) => onQuestionClick(e, question.id)}
                        >
                            <div className="faq-section--questions--toggle"><i className="fa fa-chevron-down"></i></div>
                            <div className="faq-section--questions--question">{question.question}</div>
                            <div className="faq-section--questions--answer"
                                dangerouslySetInnerHTML={stringAsHtml(question.answer)}
                                style={{ 
                                    maxHeight: (questionIsOpen(question.id) && questionHeight.find(q => q.id === question.id)) ? getQuestionHeight(question.id) : '0',
                                    height: (questionIsOpen(question.id) && questionHeight.find(q => q.id === question.id)) ? 'auto' : '0'
                                }}></div>
                        </li>
                    )
                }
            </ul>
        </div>
    );
}
  
export default FAQ;