import './style.scss';
import { getChannelName } from '../../App';

function SocialBar(props) {
    
    const socialLinks = [
        {id: 'twitter', name: 'Twitter', icon: 'twitter'},
        {id: 'facebook', name: 'Facebook', icon: 'facebook'},
        {id: 'youtube', name: 'Youtube', icon: 'youtube'},
        {id: 'instagram', name: 'Instagram', icon: 'instagram'}
    ];

    const addSocialLinkUrls = (links) => {
        /*  
            links:
            [{id: 'twitter', link: 'https://...'}]
        */
        const _socialLinks = [...socialLinks];
        return _socialLinks.map(socialLink => {
            const linkData = links.find(link => link.id === socialLink.id);
            return {...socialLink, ...linkData};
        }).filter(item => item.link);

    }

    const getSocialIcons = (channel) => {
        switch (channel) {
            case 'martinservera':
                return addSocialLinkUrls([
                    {id: 'instagram', link: 'https://www.instagram.com/martinservera/'},
                    {id: 'twitter', link: 'https://twitter.com/MartinServera'},
                    {id: 'facebook', link: 'https://www.facebook.com/martinochservera/'},
                    {id: 'youtube', link: 'https://www.youtube.com/martinserverarestauranggrossist'}
                ]);
            case 'galatea':
                return addSocialLinkUrls([
                    {id: 'instagram', link: 'https://www.instagram.com/galatea.se_/'},
                    {id: 'facebook', link: 'https://www.facebook.com/GalateaBeerSpiritsWine'},
                    {id: 'youtube', link: 'https://www.youtube.com/channel/UCe8yH90TKbUh1njBHsoioFg'}
                ]);
            case 'gronsakshallen':
                return addSocialLinkUrls([
                    {id: 'instagram', link: 'https://www.instagram.com/gronsakshallensorunda/'},
                    {id: 'facebook', link: 'https://www.facebook.com/GronsakshallenSorunda/'}
                ]);
            case 'fiskhallen':
                return addSocialLinkUrls([
                    {id: 'instagram', link: 'https://www.instagram.com/fiskhallensorunda/'},
                    {id: 'facebook', link: 'https://www.facebook.com/fiskhallensorunda/'}
                ]);
            case 'kotthallen':
                return addSocialLinkUrls([
                    {id: 'instagram', link: 'https://www.instagram.com/fallmanskott'},
                    {id: 'twitter', link: 'https://twitter.com/fallmanskott'},
                    {id: 'facebook', link: 'https://www.facebook.com/fallmanskott'},
                    {id: 'youtube', link: 'https://www.youtube.com/playlist?list=PL1368B1977CACE0E3'}
                ]);
            default:
                return [];
        }
    }

    const onSocialIconClick = (icon) => {
        if(icon.link) {
            window.open(icon.link, '_blank');
        }
    }

    return (
        getSocialIcons(props.channel).length > 0 &&
            <div className="socialbar--wrapper">
                <div className={props.channel ? `socialbar socialbar--${props.channel}` : 'socialbar'}>
                    
                    Följ {getChannelName(props.channel)} för mer uppdateringar
    
                    <ul className="socialbar--icons">
                        {getSocialIcons(props.channel).map(icon => 
                            <li className="socialbar--icons--icon" key={icon.id} onClick={() => onSocialIconClick(icon)}>
                                <i className={`fa fa-${icon.icon}`}></i>
                            </li>
                        )}
                    </ul>
    
                </div>
            </div>
    );
}
  
export default SocialBar;