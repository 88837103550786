import './style/global.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Home from './pages/home';
import Channel from './pages/channel';
import NotFound from './pages/404';

// Define available channels to set up routes and themes.
export const CHANNELS = [
  'martinservera',
  'galatea', 
  'raddamat',
  'gronsakshallen',
  'fiskhallen',
  'kotthallen'
]

export const getChannelName = (channel) => {
  switch (channel) {
      case 'martinservera':
        return 'Martin & Servera';
      case 'galatea':
        return 'Galatea';
      case 'raddamat':
        return 'Rädda Mat';
      case 'kotthallen':
        return 'Kötthallen Sorunda';
      case 'gronsakshallen':
        return 'Grönsakshallen Sorunda';
      case 'fiskhallen':
        return 'Fiskhallen Sorunda';
      default:
          return channel;
  }
}

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        { CHANNELS.map(channel => <Route path={`/${channel}`} element={<Channel />} key={channel} />) }
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
