import './style.scss';

function Footer() {

  const footerFragments = [
    {
      id: 'press_information',
      title: 'Press & Information',
      items: [
        {
          id: 'press_link',
          content: 'Klicka här',
          link: 'https://martinservera.mynewsdesk.com/'
        }
      ]
    },
    {
      id: 'ms-gruppen',
      title: 'Martin & Servera-gruppen',
      items: [
        { 
          id: 'box',
          content: 'Box 1003'
        },
        { 
          id: 'address', 
          content: '121 23 Johanneshov'
        }
      ]
    }
  ]

  const fragmentItem = (fragment) => {
    return fragment?.link ?
      (
        <li className="footer--menu--item" key={fragment.id}>
          <a href={fragment.link} target="_blank" rel="noreferrer" className="footer--link">{fragment.content}</a>
        </li>
      ) :
      (
        <li className="footer--menu--item" key={fragment.id}>
          {fragment.content}
        </li>
      );
  }

  return (
    <div className="footer--wrapper">

      <div className="footer--content">
        <div className="footer--fragment">
          <img src="https://media.martinservera.se/f_auto,q_auto/logo/martin_servera-gruppen_kvadrat_WHITE.png" className="footer--logo" alt="Martin & Servera Gruppen" />
        </div>

        {
          footerFragments.map(fragment => (
            <div className="footer--fragment" key={fragment.id}>
              <ul className="footer--menu">
                <li className="footer--menu--item footer--menu--title">{fragment.title}</li>
                {fragment.items.map(item => fragmentItem(item))}
              </ul>
            </div>
          ))
        }
      </div>

      <div className="footer--signoff">
        <span className="footer--signoff--element">© Martin & Servera-gruppen</span>
        <span className="footer--signoff--element">Orgnr: 556233-2451</span>
      </div>

    </div>
  );
}

export default Footer;