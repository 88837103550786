const content = [
  {
    id: "kontakt",
    title: "Kontaktinformation",
    items: [
      {
        id: "besok",
        title: "Besöksadress",
        items: [
          {
            id: "besok-1",
            content: "Fiskhallen Sorunda AB",
            type: "margin-y"
          },
          {
            id: "besok-2",
            content: "Elektravägen 15, 126 30 Hägersten",
            type: "margin-y"
          },
          {
            id: "besok-3",
            content: "Visa på karta",
            link: "https://www.google.com/maps/place/Fiskhallen+Sorunda/@59.2964704,18.0126533,17z/data=!3m1!4b1!4m5!3m4!1s0x465f77a214ca7dbd:0xbb5212293c4bfe82!8m2!3d59.2964677!4d18.014842",
            type: "margin-y"
          }
        ]
      },
      {
        id: "kundservice",
        title: "Kundservice",
        items: [
          {
            id: "kundservice-1",
            content: "08-501 345 00",
            link: "tel:0850134500",
            type: "margin-y"
          },
          {
            id: "kundservice-2",
            content: "info@fiskhallen.se",
            link: "mailto:info@fiskhallen.se",
            type: "margin-y"
          }
        ]
      },
      {
        id: "oppettider",
        title: "Öppettider",
        items: [
          {
            id: "oppettider-1",
            content: "Mån-Fre 07:00-17:00",
            type: "margin-y"
          },
          {
            id: "oppettider-2",
            content: "Övrig tid telefonsvarare",
            type: "margin-y"
          }
        ]
      }
    ]
  },
  {
    id: "avdelningar",
    title: "Avdelningar",
    footer: "Öppettider för samtliga: Mån-Fre 07:00-17:00",
    items: [
      {
        id: "hr",
        title: "HR / Personal",
        items: [
          {
            id: "hr-1",
            content: "Maila HR",
            link: "mailto:hr@gronsakshallen.se",
            type: "margin-y"
          }
        ]
      },
      {
        id: "hok",
        title: "Hållbarhet & kvalitet",
        items: [
          {
            id: "hok-1",
            content: "Maila HUK",
            link: "mailto:huk@sorundahallarna.se",
            type: "margin-y"
          }
        ]
      },
      {
        id: "ekonomi",
        title: "Ekonomi",
        items: [
          {
            id: "ekonomi-1",
            content: "08-506 258 40",
            link: "tel:0850625840",
            type: "margin-y"
          },
          {
            id: "ekonomi-2",
            content: "Maila ekonomi",
            link: "mailto:ekonomi@fiskhallen.se",
            type: "margin-y"
          }
        ]
      },
      {
        id: "marknad",
        title: "Marknad",
        items: [
          {
            id: "marknad-1",
            content: "Maila marknad",
            link: "mailto:marknadsavdelningen@gronsakshallen.se",
            type: "margin-y"
          }
        ]
      }
    ]
  },
];

export default content;