import { Link } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";

function NotFound() {
    return (
      <>
        <Header />
        
        <div className="content--wrapper valign-center">
          <div className="content text-center">
            <h2>Sidan kunde inte hittas</h2>
            <p>Sidan kunde inte hittas. <Link to="/">Till startsidan</Link></p>
          </div>
        </div>

        <Footer />
      </>
    );
}
  
export default NotFound;