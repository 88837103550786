import { Link } from "react-router-dom";
import './style.scss';

function BrandCard(props) {

    return (
        <li className={`brand-card brand-card--${props.channel}`}>
                <div className='brand-card--image-wrap'>
                    <img className={`brand-card--image brand-card--image-${props.channel}`} src={props.imageHref} alt={`${props.title} symbol`} />
                </div>
                <span className='brand-card--title'>{props.title}</span>
                <div className='brand-card--cta'>
                    <Link to={`/${props.channel}`} key={props.channel} className='brand-card--link'>
                        <button>Mer info</button>
                    </Link>
                </div>
        </li>
    );

}

export default BrandCard;