import { Link } from "react-router-dom";
import { useState } from "react";
import './style.scss';
import {CHANNELS, getChannelName} from "../../App";
import FlyoutMenu from "../flyout-menu";

function Header(props) {
    const [flyoutOpen, setFlyoutOpen] = useState(false);
    
    return (
        <>
            <FlyoutMenu open={flyoutOpen} closeMenu={() => setFlyoutOpen(false)} />
            <div className={props.channel ? `header header-${props.channel}` : 'header'}>
                
                <div className="header--mobile-menu">
                    <div className="header--mobile-menu--trigger header--link" onClick={() => setFlyoutOpen(!flyoutOpen)}>
                        <i className="fa fa-bars"></i>
                    </div>
                    <div className="header--mobile-menu--logo">
                        <Link to={'/'}>
                                <img src={
                                        props.channel === 'home' ? 
                                        'https://media.martinservera.se/f_auto,q_auto/logo/ms-gruppen_logo_kvadrat_black.png' :
                                        'https://media.martinservera.se/f_auto,q_auto/logo/martin_servera-gruppen_kvadrat_WHITE.png'
                                    }
                                    className="header--mobile-menu--logo--image" 
                                    alt="Martin & Servera Gruppen" 
                                />
                        </Link>
                    </div>
                </div>

                <Link to={'/'} className={props.channel === 'home' ? 'header--link is-active' : 'header--link'}>
                    Hem
                    <div className='header--link-highlight'></div>
                </Link>
                {CHANNELS.map((channel) => {
                    return (
                        <Link to={`/${channel}`} key={channel} className={channel === props.channel ? 'header--link is-active' : 'header--link'}>
                            { getChannelName(channel) }
                            <div className='header--link-highlight'></div>
                        </Link>
                    )
                })}
            </div>
        </>
    );
}
  
export default Header;