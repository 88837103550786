import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { CHANNELS, getChannelName } from "../../App";
import "./style.scss";
import Header from "../../components/header";
import Footer from "../../components/footer";
import SocialBar from "../../components/social-bar";
import InfoBlock from "../../components/info-block";

function Channel() {
  const [channel, setChannel] = useState("default");

  // Set channel theme based on route. Use 'default' if channel is not found.
  let location = useLocation();
  useEffect(() => {
    const theme = location.pathname.replace("/", "");
    CHANNELS.includes(theme) ? setChannel(theme) : setChannel("default");
  }, [location]);

  const logoUrl = (channel) => {
    switch (channel) {
      case 'martinservera':
        return 'https://media.martinservera.se/f_auto,q_auto/logo/ms-logo-hi.png';
      case 'raddamat':
        return 'https://media.martinservera.se/f_auto,q_auto/logo/radda_mat_logo_gron.png';
      case 'galatea':
        return 'https://media.galatea.se/f_auto,q_auto/logo/galatea_logo.png';
      case 'gronsakshallen':
        return 'https://media.martinservera.se/f_auto,q_auto/logo/gronsakshallen_logo.png';
      case 'kotthallen':
        return 'https://media.martinservera.se/f_auto,q_auto/logo/kotthallen_logo.png';
      case 'fiskhallen':
        return 'https://media.martinservera.se/f_auto,q_auto/logo/fiskhallen_logo.png';
      default:
        return null;
    }
  } 

  return (
    <>
      <Header channel={channel} />

      <div className="content--wrapper">
        <div className="content text-center">

          { logoUrl(channel) &&
            <div className={`logo--wrapper logo--wrapper--${channel}`}>
              <img src={logoUrl(channel)} alt={getChannelName(channel)} />
            </div>
          }

          <InfoBlock channel={channel} />

        </div>
      </div>

      <SocialBar channel={channel} />

      <Footer />
    </>
  );
}

export default Channel;
