import './style.scss';
import { CHANNELS, getChannelName } from '../../App';
import BrandCard from '../brand-card';

function BrandCardsList() {

    const getBrandImageHref = channel => {

        const imageHrefs = {
            'martinservera': 'https://media.martinservera.se/f_auto,q_auto/logo/ms-logo-hi.png',
            'galatea': 'https://media.martinservera.se/f_auto,q_auto/logo/galatea_symbol.png',
            'raddamat': 'https://media.martinservera.se/f_auto,q_auto/logo/radda_mat_hjartat-svart.png',
            'gronsakshallen': 'https://media.martinservera.se/f_auto,q_auto/logo/gronsakshallen_illustration_hel.png',
            'fiskhallen': 'https://media.martinservera.se/f_auto,q_auto/logo/fiskhallen_illustration_hel.png',
            'kotthallen': 'https://media.martinservera.se/f_auto,q_auto/logo/kotthallen_illustration_hel.png'
        };

        return imageHrefs.hasOwnProperty(channel) ? imageHrefs[channel] : false;
    };

    const getBrandTitle = channel => {

        const titles = {
            'martinservera': 'Martin & Servera Restauranghandel',
            'gronsakshallen': 'Grönsakshallen Sorunda',
            'fiskhallen': 'Fiskhallen Sorunda',
            'kotthallen': 'Kötthallen Sorunda'
        };

        return titles.hasOwnProperty(channel) ? titles[channel] : getChannelName(channel);
    };

    const getBrandCardsData = channel => {

        return  {
            'channel': channel,
            'title': getBrandTitle(channel),
            'imageHref': getBrandImageHref(channel)
        };

    }

    return (
        <ol className='brand-cards-list'>
            {CHANNELS.map(getBrandCardsData).map(cardData => cardData && 
                <BrandCard key={cardData.channel} channel={cardData.channel} title={cardData.title} imageHref={cardData.imageHref} />
            )}
        </ol>
    );

}

export default BrandCardsList;