const content = [
  {
    id: "kontakt",
    title: "Kontaktinformation",
    link: {
      title: "Logga in på webbshoppen Kötthallen Sorunda",
      href: "https://kotthallen.logifresh.net/butik/login.php"
    },
    items: [
      {
        id: "besoksadress",
        title: "Besöksadress",
        items: [
          {
            id: "besoksadress-1",
            content: "Kötthallen Sorunda AB",
            type: "margin-y"
          },
          {
            id: "besoksadress-2",
            content: "Elektravägen 15, 126 30 Hägersten",
            type: "margin-y"
          },
          {
            id: "besoksadress-3",
            content: "Visa på karta",
            link: "https://goo.gl/maps/vsn93fAgr7YdWCgh6",
            type: "margin-y"
          }
        ]
      },
      {
        id: "kontakt",
        title: "Order och kundservice",
        items: [
          {
            id: "kontakt-1",
            content: "08-556 139 00",
            link: "tel:0855613900",
            type: "margin-y"
          },
          {
            id: "kontakt-2",
            content: "order@kotthallen.se",
            link: "mailto:order@kotthallen.se",
            type: "margin-y"
          }
        ]
      },
      {
        id: "filler",
        title: "Öppettider",
        items: [
          {
            id: "oppettider-1",
            content: "Mån-Fre 06:00-18:00",
            type: "margin-y"
          },
          {
            id: "oppettider-2",
            content: "Lördag: 07:00-12:00",
            type: "margin-y"
          },
          {
            id: "oppettider-3",
            content: "Övrig tid <a href='mailto:order@kotthallen.se'>ordermejl</a>",
            isHtml: true,
            type: "margin-y"
          }
        ]
      }
    ]
  },
  {
    id: "avdelningar",
    title: "Avdelningar",
    footer: "Öppettider för samtliga: Mån-Fre 07:00-17:00",
    items: [
      {
        id: "order",
        title: "Order",
        items: [
          {
            id: "order-1",
            content: "08-556 139 00",
            link: "tel:0855613900",
            type: "margin-y"
          },
          {
            id: "order-2",
            content: "Maila order",
            link: "mailto:order@fallmanskott.se",
            type: "margin-y"
          }
        ]
      },
      {
        id: "expedition",
        title: "Kundservice",
        items: [
          {
            id: "expedition-1",
            content: "08-556 139 00",
            link: "tel:0855613900",
            type: "margin-y"
          },
          {
            id: "expedition-2",
            content: "Maila transport",
            link: "mailto:transport@fallmanskott.se",
            type: "margin-y"
          }
        ]
      },
      {
        id: "inkop",
        title: "Inköp",
        items: [
          {
            id: "inkop-1",
            content: "08-556 139 04",
            link: "tel:0855613904",
            type: "margin-y"
          },
          {
            id: "inkop-2",
            content: "Maila inköp",
            link: "mailto:inkop@fallmanskott.se",
            type: "margin-y"
          }
        ]
      }
    ]
  }
];

export default content;