const content = [
  {
    id: "kontakt",
    title: "Kontaktinformation",
    items: [
      {
        id: "kundtjanst-hlm",
        title: "Kundtjänst Halmstad",
        items: [
          {
            id: "kundtjanst-hlm-1",
            content: "035-18 30 50",
            link: "tel:035183050",
            type: "margin-y"
          },
          {
            id: "kundtjanst-hlm-2",
            content: "Mån-Fre: 07:45-16:30",
          },
          {
            id: "kundtjanst-hlm-3",
            content: "Lördagar: 09:00-13:00",
          }
        ]
      },
      {
        id: "kundtjanst-nrkpg",
        title: "Kundtjänst Norrköping",
        items: [
          {
            id: "kundtjanst-nrkpg-1",
            content: "011-20 29 98",
            link: "tel:011202998",
            type: "margin-y"
          },
          {
            id: "kundtjanst-nrkpg-2",
            content: "Mån-Fre: 08:00-17:00",
          },
          {
            id: "kundtjanst-nrkpg-3",
            content: "Lördagar: 09:00-13:00",
          }
        ]
      },
      {
        id: "kundtjanst-umea",
        title: "Kundtjänst Umeå",
        items: [
          {
            id: "kundtjanst-umea-1",
            content: "090-17 16 65",
            link: "tel:090171665",
            type: "margin-y"
          },
          {
            id: "kundtjanst-umea-2",
            content: "Mån-Fre: 07:00-16:00",
          },
          {
            id: "kundtjanst-umea-3",
            content: "Lördagar: 09:00-12:00",
          }
        ]
      }
    ]
  }
];

export default content;