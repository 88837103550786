const content = [
  {
    id: "sthlm",
    title: "Grönsakshallen Stockholm",
    link: {
      title: "Logga in på webshoppen Stockholm",
      href: "https://gronsakshallen.logifresh.net/butik/login.php"
    },
    items: [
      {
        id: "sthlm-adress",
        title: "Besöksadress",
        items: [
          {
            id: "sthlm-adress-1",
            content: "Grönsakshallen Sorunda AB",
            type: "margin-y"
          },
          {
            id: "sthlm-adress-2",
            content: "Elektravägen 15, 126 30 Hägersten",
            type: "margin-y"
          },
          {
            id: "sthlm-adress-3",
            content: "Visa på karta",
            link: "https://www.google.com/maps/place/Gr%C3%B6nsakshallen+Sorunda+Stockholm/@59.2964704,18.0126533,17z/data=!3m2!4b1!5s0x465f77a5704bf253:0xd37c0c85db2b3af0!4m5!3m4!1s0x465f77a56a0c464f:0x8380a0a9caaa7bd0!8m2!3d59.2964677!4d18.014842",
            type: "margin-y"
          }
        ]
      },
      {
        id: "sthlm-order",
        title: "Order och kundservice",
        items: [
          {
            id: "sthlm-order-1",
            content: "08-501 258 00",
            link: "tel:0850125800",
            type: "margin-y"
          },
          {
            id: "sthlm-order-2",
            content: "Maila order & kundservice",
            link: "mailto:order@gronsakshallen.se",
            type: "margin-y"
          }
        ]
      },
      {
        id: "sthlm-oppettider",
        title: "Öppettider",
        items: [
          {
            id: "sthlm-oppettider-1",
            content: "Mån-Fre: 06:00-01:00",
            type: "margin-y"
          },
          {
            id: "sthlm-oppettider-2",
            content: "Lördag: 06:00-15:00",
            type: "margin-y"
          },
          {
            id: "sthlm-oppettider-3",
            content: "Söndag: 06:00-01:00",
            type: "margin-y"
          }
        ]
      }
    ]
  },
  {
    id: "syd",
    title: "Grönsakshallen Syd",
    link: {
      title: "Logga in på webshoppen Syd",
      href: "https://gronsakshallensyd.logifresh.net/butik/login.php"
    },
    items: [
      {
        id: "syd-adress",
        title: "Besöksadress",
        items: [
          {
            id: "syd-adress-1",
            content: "Grönsakshallen Sorunda AB",
            type: "margin-y"
          },
          {
            id: "syd-adress-2",
            content: "PI. 5246 Röinge, 281 92 Hässleholm",
            type: "margin-y"
          },
          {
            id: "syd-adress-3",
            content: "Visa på karta",
            link: "https://www.google.com/maps/place/Gr%C3%B6nsakshallen+Sorunda+Syd/@56.1569448,13.8258683,17z/data=!3m1!4b1!4m5!3m4!1s0x465155fccc2b676f:0x1b9cbd08c3021e36!8m2!3d56.1569418!4d13.828057",
            type: "margin-y"
          }
        ]
      },
      {
        id: "syd-order",
        title: "Order och kundservice",
        items: [
          {
            id: "syd-order-1",
            content: "0451-351 00",
            link: "tel:045135100",
            type: "margin-y"
          },
          {
            id: "syd-order-2",
            content: "Maila order & kundservice",
            link: "mailto:ghsyd.info@gronsakshallen.se",
            type: "margin-y"
          }
        ]
      },
      {
        id: "syd-oppettider",
        title: "Öppettider",
        items: [
          {
            id: "syd-oppettider-1",
            content: "Mån-Fre: 07:00-24:00",
            type: "margin-y"
          },
          {
            id: "syd-oppettider-2",
            content: "Lördag (endast jour): 07:00-15:45",
            type: "margin-y"
          },
          {
            id: "syd-oppettider-3",
            content: "Söndag: 16:15-24:00",
            type: "margin-y"
          }
        ]
      }
    ]
  },
  {
    id: "vast",
    title: "Grönsakshallen Väst",
    link: {
      title: "Logga in på webshoppen Västkusten",
      href: "https://gronsakshallenvastkusten.logifresh.net/butik/login.php"
    },
    items: [
      {
        id: "vast-adress",
        title: "Besöksadress",
        items: [
          {
            id: "vast-adress-1",
            content: "Grönsakshallen Sorunda AB",
            type: "margin-y"
          },
          {
            id: "vast-adress-2",
            content: "Exportgatan 15, 422 46 Hissings Backa",
            type: "margin-y"
          },
          {
            id: "vast-adress-3",
            content: "Visa på karta",
            link: "https://www.google.com/maps/place/Gr%C3%B6nsakshallen+Sorunda+V%C3%A4stkusten/@57.7318161,11.9869221,17z/data=!3m1!4b1!4m5!3m4!1s0x464ff5bf329de861:0xfa36ce2d284bc309!8m2!3d57.7321221!4d11.9892866",
            type: "margin-y"
          }
        ]
      },
      {
        id: "vast-order",
        title: "Order och kundservice",
        items: [
          {
            id: "vast-order-1",
            content: "031-380 12 00",
            link: "tel:0313801200",
            type: "margin-y"
          },
          {
            id: "vast-order-2",
            content: "Maila order & kundservice",
            link: "mailto:gbg.order@gronsakshallen.se",
            type: "margin-y"
          }
        ]
      },
      {
        id: "vast-oppettider",
        title: "Öppettider",
        items: [
          {
            id: "vast-oppettider-1",
            content: "Mån-Fre: 07:00-16:00, 18:30-24:00",
            type: "margin-y"
          },
          {
            id: "vast-oppettider-2",
            content: "Söndag: 16:00-24:00",
            type: "margin-y"
          },
          {
            id: "vast-oppettider-3",
            content: "Söndag: 16:15-24:00",
            type: "margin-y"
          }
        ]
      }
    ]
  },
  {
    id: "avdelningar",
    title: "Avdelningar",
    footer: "Öppettider för samtliga: Mån-Fre 07:00-17:00",
    items: [
      {
        id: "avdelningar-hr",
        title: "HR / Personal",
        items: [
          {
            id: "avdelningar-hr-1",
            content: "Maila HR",
            link: "mailto:hr@gronsakshallen.se",
            type: "margin-y"
          }
        ]
      },
      {
        id: "avdelningar-huk",
        title: "Hållbarhet & kvalitet",
        items: [
          {
            id: "avdelningar-huk-1",
            content: "Maila HUK",
            link: "mailto:huk@sorundahallarna.se",
            type: "margin-y"
          }
        ]
      },
      {
        id: "avdelningar-ekonomi",
        title: "Ekonomi",
        items: [
          {
            id: "avdelningar-ekonomi-1",
            content: "08-506 258 40",
            link: "tel:0850625840",
            type: "margin-y"
          },
          {
            id: "avdelningar-ekonomi-2",
            content: "Maila ekonomi",
            link: "mailto:ekonomi@gronsakshallen.se",
            type: "margin-y"
          }
        ]
      },
      {
        id: "avdelningar-marknad",
        title: "Marknad",
        items: [
          {
            id: "avdelningar-marknad-1",
            content: "Maila marknad",
            link: "mailto:marknadsavdelningen@gronsakshallen.se",
            type: "margin-y"
          }
        ]
      }
    ]
  }
];

export default content;